<template>
  <div v-if="data.payment">
    <template v-if="data.payment.subscription">
      <h5>Abonnement</h5>
      <dl class="row">
        <dt class="col-sm-4 text-right text-muted">#</dt>
        <dd class="col-sm-8">
          {{ data.payment.subscription.subscriptionId }}
        </dd>
        <dt class="col-sm-4 text-right text-muted">Début du cycle</dt>
        <dd class="col-sm-8">
          {{
            $dayjs(data.payment.subscription.effectDate).format('DD-MM-YYYY')
          }}
        </dd>
        <dt class="col-sm-4 text-right text-muted">Annulé le</dt>
        <dd class="col-sm-8">
          <b v-if="data.payment.subscription.cancelDate" class="text-danger">
            {{
              $dayjs(data.payment.subscription.cancelDate).format('DD-MM-YYYY')
            }}
          </b>
          <span v-else>-</span>
        </dd>
      </dl>
    </template>
    <template v-if="data.payment.order">
      <h5>Commande initiale</h5>
      <dl class="row">
        <dt class="col-sm-4 text-right text-muted">#</dt>
        <dd class="col-sm-8">
          <router-link :to="`/orders/${data.payment.order.id}`">
            {{ data.payment.order.id }}
          </router-link>
        </dd>
        <dt class="col-sm-4 text-right text-muted">Paiement #</dt>
        <dd class="col-sm-8">
          {{ data.payment.order.payment_id }}
        </dd>
        <template v-if="data.payment.order.transaction">
          <dt class="col-sm-4 text-right text-muted">Moyen de paiement</dt>
          <dd class="col-sm-8">
            {{ data.payment.order.transaction.paymentMethodType }}
          </dd>
          <dt class="col-sm-4 text-right text-muted">Status</dt>
          <dd class="col-sm-8">
            {{ data.payment.order.transaction.status }}
            ({{ data.payment.order.transaction.detailedStatus }})
          </dd>
          <dt class="col-sm-4 text-right text-muted">Créé le</dt>
          <dd class="col-sm-8">
            {{
              $dayjs(data.payment.order.transaction.creationDate).format(
                'DD-MM-YYYY'
              )
            }}
          </dd>
          <dt class="col-sm-4 text-right text-muted">Opération</dt>
          <dd class="col-sm-8">
            {{ data.payment.order.transaction.operationType }}
          </dd>
        </template>
      </dl>
    </template>
    <template v-if="data.payment.payment_data">
      <h5>Moyen de paiement</h5>
      <dl class="row">
        <dt class="col-sm-4 text-right text-muted">Empreinte</dt>
        <dd class="col-sm-8">
          {{ data.payment.payment_data.card_pan }}
        </dd>
        <dt class="col-sm-4 text-right text-muted">Expiration</dt>
        <dd class="col-sm-8">
          {{ data.payment.payment_data.card_expiry_month }}/{{
            data.payment.payment_data.card_expiry_year
          }}
        </dd>
        <dt class="col-sm-4 text-right text-muted">Origine</dt>
        <dd class="col-sm-8">
          {{ data.payment.payment_data.card_country }}
        </dd>
      </dl>
    </template>
    <!-- <pre>{{ data }}</pre> -->
  </div>
</template>

<script>
export default {
  name: 'UserPaymentInformationsCard',
  props: {
    subscriptionId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const resp = await this.$http.get(
        `/subscriptions/${this.subscriptionId}/informations/`
      )
      this.data = resp.data
    },
  },
}
</script>
