<template>
  <div>
    <b-card v-if="subscription.id">
      <div class="row">
        <div class="col-lg-6">
          <h3>
            {{ subscription.plan.label }}
            <br />
            <small>
              Créé le {{ $dayjs(subscription.created_at).format('DD-MM-YYYY') }}
              Mise à jour le
              {{ $dayjs(subscription.updated_at).format('DD-MM-YYYY à HH:mm') }}
            </small>
          </h3>
        </div>
        <div class="col-lg-6 text-right">
          <button
            class="btn btn-info btn-sm mr-1"
            @click.prevent="displayCoupon = !displayCoupon"
          >
            Bon de réduction
          </button>
          <button
            v-if="subscription.payment_service == 'PAYZEN'"
            class="btn btn-info btn-sm mr-1"
            @click.prevent="
              displayPaymentInformations = !displayPaymentInformations
            "
          >
            {{ subscription.payment_service }}
          </button>
          <button
            v-if="
              ['PAYZEN', 'LEONETMARCEL'].includes(
                subscription.payment_service
              ) == true &&
              (subscription.status == 'ENABLED' ||
                subscription.status == 'PAUSE' ||
                subscription.status == 'CANCEL_AT_PERIOD_END' ||
                subscription.status == 'TRY_TO_CANCEL')
            "
            class="btn btn-info btn-sm"
            @click.prevent="
              displayCancelSubscription = !displayCancelSubscription
            "
          >
            Résiliation
          </button>
        </div>
      </div>
      <div
        v-if="subscription.id && displayCoupon == true"
        class="m-1 p-2 border border"
      >
        <h4>Bon de réduction</h4>
        <div v-if="subscription.id && subscription.user_coupon">
          L'abonnement bénéficie d'un bon de réduction :
          <b>
            {{ subscription.user_coupon.coupon.label }}
            ({{ subscription.user_coupon.coupon.public_id }})
          </b>
          <div class="mt-2">
            <button class="btn btn-danger" @click.prevent="deleteCoupon()">
              Dissocier le bon de réduction
            </button>
          </div>
        </div>
        <div v-else>
          <div class="mt-2">
            <b-form-group label-for="input-public_id" label="ID">
              <b-form-input
                id="input-public_id"
                v-model="couponPublicId"
                type="text"
              />
            </b-form-group>
            <button class="btn btn-primary" @click.prevent="attachCoupon()">
              Associer le bon de réduction
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="subscription.id && displayCancelSubscription == true"
        class="m-1 p-2 border border"
      >
        <h4>Résilier l’abonnement</h4>
        <el-form ref="form" label-position="top">
          <el-form-item label="Type de résiliation">
            <el-radio v-model="cancelSubscriptionStatus" label="CANCELED">
              Immédiate
            </el-radio>
            <el-radio
              v-if="subscription.status !== 'CANCEL_AT_PERIOD_END'"
              v-model="cancelSubscriptionStatus"
              label="CANCEL_AT_PERIOD_END"
            >
              A la fin de la sélection en cours
            </el-radio>
            <p v-if="cancelSubscriptionStatus == 'CANCELED'">
              <b>L’abonnement sera résilié immédiatement.</b> Si une sélection
              est en cours, celle-ci sera supprimée. Le client ne sera pas livré
              pour la semaine en cours à la date de la résiliation.
              <br />
              Si c'est la première sélection, le client
            </p>
            <p v-if="cancelSubscriptionStatus == 'CANCEL_AT_PERIOD_END'">
              <b>L’abonnement sera résilié à la fin de la période en cours.</b>
              Le sélection de plats actuelle sera livrée pour la semaine en
              cours et le client sera débité une dernière fois. seront annulés.
            </p>
            <p>
              <b>La résiliation ne peut être annulée</b>
            </p>
          </el-form-item>
        </el-form>
        <button
          class="btn btn-warning btn-sm"
          @click.prevent="subscriptionCancel()"
        >
          Résilier
        </button>
      </div>
      <div
        v-if="subscription.id && displayPaymentInformations == true"
        class="m-1 p-2 border border"
      >
        <h4>Configuration du service de paiment</h4>
        <p>
          Ces informations sont en provenance du service de paiement et
          reflètent la configuration client actuelle.
        </p>
        <user-subscription-payment-card :subscription-id="subscription.id" />
      </div>
      <b-tabs content-class="mt-2">
        <b-tab title="Propriétés" active>
          <user-subscription-card
            :display-diet="true"
            :display-allergies="true"
            :subscription="subscription"
          />
        </b-tab>
        <b-tab title="Métadonnées">
          <pre>{{ subscription }}</pre>
          <pre>{{ selections }}</pre>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-card>
      <div class="row">
        <div class="col-md-6">
          <h3>Sélections hebdomadaires</h3>
        </div>
        <div class="col-md-6 text-right"></div>
      </div>
      <p v-if="selections.length == 0" class="text-center text-muted">
        <em> Aucune sélection </em>
      </p>
      <table class="mt-2 table table-hover">
        <thead>
          <tr>
            <th>Livraison</th>
            <th class="text-right">Sélection</th>
            <th class="text-right">Statut</th>
            <th class="text-right">Règlement</th>
            <th class="text-right">Montant</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="selection in selections">
            <tr
              :key="selection.id"
              @click.prevent="
                $router.push(`/subscriptions/selections/${selection.id}`)
              "
            >
              <td>
                <h4>
                  <span v-if="$dayjs().week() == selection.week_of_year">
                    <font-awesome-icon icon="calendar-week" />
                  </span>
                  Semaine
                  <span v-if="selection.week_of_year">
                    {{ String(selection.week_of_year).padStart(2, '0') }}
                  </span>
                  <span
                    class="text-muted"
                    :title="$dayjs(selection.start_at).format('DD-MM-YYYY')"
                  >
                    Livraison le
                    {{ $dayjs(selection.delivery_at).format('DD-MM-YYYY') }}
                  </span>
                </h4>
              </td>
              <td class="text-right">
                {{ selection.recipes_relations_quantities }} /
                {{ subscription.plan.quantity }}
              </td>
              <td class="text-right">
                {{ $t(`SELECTION.${selection.status}`) }}
              </td>
              <td class="text-right">
                {{ $t(`SELECTION.PAYMENT_${selection.payment_status}`) }}
              </td>
              <td class="text-right">
                <span v-if="selection.is_first === false">
                  {{ selection.payment_amount | currency }}
                </span>
                <span v-else>-</span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </b-card>
    <!-- <debug>{{ subscription }}</debug> -->
    <!-- <debug>{{ constants }}</debug> -->
    <!-- <debug>{{ selections }}</debug> -->
  </div>
</template>

<script>
import UserSubscriptionCard from '@/components/UserSubscriptionCard.vue'
import UserSubscriptionPaymentCard from '@/components/UserSubscriptionPaymentCard.vue'
import { BCard, BTabs, BTab, BFormGroup, BFormInput } from 'bootstrap-vue'

const subscriptionFields = '*,user{id,fullname,diet,allergies}'
const selectionsFields =
  'results{' +
  'id,status.created_at,start_at,end_at,choice,week_of_year,is_first,' +
  'recipes_selection_start_at,recipes_relations_count,' +
  'recipes_relations_quantities,limit,' +
  'payment_status,payment_id,delivery_at,payment_amount,' +
  'selections_relations{id,created_at,quantity,recipe_id,recipe{label,thumb_list_url}}' +
  '}'

export default {
  components: {
    UserSubscriptionCard,
    UserSubscriptionPaymentCard,
    BTabs,
    BTab,
    BCard,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      displayCoupon: false,
      couponPublicId: null,
      displayCancelSubscription: false,
      cancelSubscriptionStatus: 'CANCEL_AT_PERIOD_END',
      displayPaymentInformations: false,
      subscription: {},
      paymentInformations: {},
      constants: {},
      selections: [],
      fillSelectionId: null,
    }
  },
  async created() {
    this.init()
  },
  methods: {
    async init() {
      const respConstants = await this.$http.get('/constants/', {
        params: { keys: 'CURRENT_WEEK,NEXT_WEEK' },
      })
      this.constants = respConstants.data
      const resp = await this.$http.get(
        `/subscriptions/${this.$route.params.id}/`,
        {
          headers: {
            'X-Fields': subscriptionFields,
          },
        }
      )
      this.subscription = resp.data

      const respSelections = await this.$http.get('/users/selections/', {
        params: {
          user_id: this.subscription.user_id,
          user_subscription_id: this.$route.params.id,
        },
        headers: {
          'X-Fields': selectionsFields,
        },
      })
      this.selections = respSelections.data.results
    },
    async subscriptionCancel() {
      const respCsrf = await this.$http.get('/auth/csrf/')
      await this.$http.delete(
        `/subscriptions/${this.$route.params.id}/${this.cancelSubscriptionStatus}/`,
        {
          headers: {
            'X-Fields': subscriptionFields,
            'X-CSRF-TOKEN': respCsrf.data.csrf,
          },
        }
      )
      this.displayCancelSubscription = false
      this.init()
    },
    async attachCoupon() {
      const respCsrf = await this.$http.get('/auth/csrf/')
      await this.$http.post(
        `/subscriptions/${this.$route.params.id}/users-coupons/${this.couponPublicId}/`,
        {
          headers: {
            'X-Fields': 'id',
            'X-CSRF-TOKEN': respCsrf.data.csrf,
          },
        }
      )
      this.displayCoupon = false
      this.init()
    },
    async deleteCoupon() {
      const respCsrf = await this.$http.get('/auth/csrf/')
      await this.$http.delete(
        `/subscriptions/${this.$route.params.id}/users-coupons/`,
        {
          headers: {
            'X-Fields': 'id',
            'X-CSRF-TOKEN': respCsrf.data.csrf,
          },
        }
      )
      this.displayCoupon = false
      this.init()
    },
  },
}
</script>
