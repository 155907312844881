<template>
  <dl class="row">
    <dt class="col-sm-4 text-right text-muted">#</dt>
    <dd class="col-sm-8">
      <b v-if="linkActive == false"><copy-value :value="subscription.id" /></b>
      <router-link
        v-else
        :to="{ name: 'subscription', params: { id: subscription.id } }"
      >
        {{ subscription.id }}
      </router-link>
    </dd>
    <template v-if="smallCard == false && subscription.user">
      <dt class="col-sm-4 text-right text-muted">Client</dt>
      <dd class="col-sm-8">
        <router-link
          :to="{ name: 'client', params: { id: subscription.user.id } }"
        >
          <b>{{ subscription.user.fullname }}</b>
        </router-link>
      </dd>
    </template>
    <dt class="col-sm-4 text-right text-muted">Statut</dt>
    <dd class="col-sm-8">
      <b v-if="subscription.status == 'ENABLED'" class="badge badge-success">
        {{ $t(`SUBSCRIPTION.${subscription.status}`) }}
      </b>
      <b
        v-else-if="subscription.status == 'CANCELED'"
        class="badge badge-danger"
      >
        {{ $t(`SUBSCRIPTION.${subscription.status}`) }}
      </b>
      <b v-else class="badge badge-dark">
        {{ $t(`SUBSCRIPTION.${subscription.status}`) }}
      </b>
    </dd>
    <dt class="col-sm-4 text-right text-muted">Formule</dt>
    <dd class="col-sm-8">
      <b>{{ subscription.plan.label }}</b>
    </dd>
    <dt class="col-sm-4 text-right text-muted">Tarif</dt>
    <dd class="col-sm-8">
      <b>{{ subscription.plan.price | currency }}</b>
    </dd>
    <dt class="col-sm-4 text-right text-muted">Service</dt>
    <dd class="col-sm-8">
      <b>{{ subscription.payment_service }}</b>
    </dd>
    <dt class="col-sm-4 text-right text-muted">Début du cycle</dt>
    <dd class="col-sm-8">
      <b>{{ $dayjs(subscription.start_at).format('DD-MM-YYYY') }}</b>
    </dd>
    <template v-if="subscription.has_started == false">
      <dt class="col-sm-4 text-right text-muted">Première livraison</dt>
      <dd class="col-sm-8">
        <b>
          {{
            $dayjs(subscription.first_week_delivery_at).format(
              'DD-MM-YYYY (dddd)'
            )
          }}
        </b>
      </dd>
      <dt class="col-sm-4 text-right text-muted">Prochain prélèvement</dt>
      <dd class="col-sm-8">
        <b>
          {{
            $dayjs(subscription.first_recurring_payment_at).format(
              'DD-MM-YYYY (dddd)'
            )
          }}
        </b>
      </dd>
    </template>
    <template v-if="subscription.has_started == true">
      <dt class="col-sm-4 text-right text-muted">Prochain cycle</dt>
      <dd class="col-sm-8">
        <b>
          {{ $dayjs(subscription.next_week_start_at).format('DD-MM-YYYY') }}
        </b>
      </dd>
      <dt class="col-sm-4 text-right text-muted">Prochaine livraison</dt>
      <dd class="col-sm-8">
        <b>
          {{
            $dayjs(subscription.next_week_delivery_at).format(
              'DD-MM-YYYY (dddd)'
            )
          }}
        </b>
      </dd>
      <dt class="col-sm-4 text-right text-muted">Prochain prélèvement</dt>
      <dd class="col-sm-8">
        <b>
          {{
            $dayjs(subscription.next_week_recurring_payment_at).format(
              'DD-MM-YYYY (dddd)'
            )
          }}
        </b>
      </dd>
    </template>
    <template v-if="subscription.cancelled_at">
      <dt class="col-sm-4 text-right text-muted">Fin de l’abonnement</dt>
      <dd class="col-sm-8">
        <b>{{ $dayjs(subscription.cancelled_at).format('DD-MM-YYYY') }}</b>
      </dd>
    </template>
    <template v-if="displayAllergies == true && subscription.user">
      <dt class="col-sm-4 text-right text-muted">Allergies</dt>
      <dd class="col-sm-8">
        <b
          v-if="
            subscription.user.allergies &&
            subscription.user.allergies.length > 0
          "
        >
          {{ subscription.user.allergies.join(', ') }}
        </b>
        <span v-else>-</span>
      </dd>
    </template>
    <template v-if="displayDiet == true && subscription.user">
      <dt class="col-sm-4 text-right text-muted">Régime</dt>
      <dd class="col-sm-8">
        <b v-if="subscription.user.diet">{{ $t(subscription.user.diet) }}</b>
        <span v-else>-</span>
      </dd>
    </template>
    <template v-if="subscription.user_coupon">
      <dt class="col-sm-4 text-right text-muted">Bon de réduction</dt>
      <dd class="col-sm-8">
        {{ subscription.user_coupon.coupon.label }}
        ({{ subscription.user_coupon.coupon.public_id }})
      </dd>
    </template>
  </dl>
</template>

<script>
export default {
  name: 'UserSubscriptionCard',
  props: {
    subscription: {
      type: Object,
      default() {
        return {}
      },
    },
    smallCard: {
      type: Boolean,
      default() {
        return false
      },
    },
    displayAllergies: {
      type: Boolean,
      default() {
        return false
      },
    },
    displayDiet: {
      type: Boolean,
      default() {
        return false
      },
    },
    linkActive: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
}
</script>
